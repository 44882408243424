@import 'variables';

body {
    color: #999; /* update this to site body colour */
    font-family: $font;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.075em;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
ul,
ol { /* align the marker with the edge */
    padding-left: 20px;
    li {
        padding-left: 10px;
    }
}
strong {
    font-weight: 500;
}
hr {
    width: 640px;
    max-width: 80%;
    height: 10px;
    background-color: $primary;
    border: 0px;
    margin: 3em 0px;
    margin-right: auto;
    &.center {
        margin: 3em auto;
    }
}
h1,
h2,
h3,
h4 {
    color: $primary;
    text-transform: uppercase;
    font-weight: 500;
}
h1 {
    font-size: 4.16em;
    &.title {
        color: #fff;
        margin: 0;
        line-height: 1;
    }
}

h2 {
    font-size: 4em;
    &.left,
    &.right {
        position: relative;
        display: inline-block; /* line ends with text, but text align won't work*/
        margin: 0px;
        margin-bottom: 10px;
        &::after {
            content:'';
            position: absolute;
            bottom: -10px;
            width: 100vw;
            display: block;
            height: 10px;
            background-color: $primary;
        }
    }
    &.left {
        &::after {
            right: 0px;
        }
    }
    &.right {
        &::after {
            left: 0px;
        }
    }
}
h3 {
    font-size: 1.94em;
    line-height: 1;
}

h4 {
    font-size: 1.3em;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.info-button,
.button {
    color: $primary;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding-right: 10px;
    max-width: 100%;
    &:before {
        content:"";
        @include before_circle;
    }
    &:active,
    &.active,
    &:hover {
        &:before {
            background-color: $primary;
        }
    }
}
.info-button {
    display: block;
    text-align: left;
    padding-right: 0px;
    cursor: pointer;
    margin: 5px 0px;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

div.yellow-border {
    box-sizing: border-box;
    border: 10px $primary solid;
    max-width: 48%;
    float: right;
    padding: 45px;
    /*margin: 20px;*/
    margin: 40px 0px;
    h3 {
        padding: 0px;
        margin-top: 0px;
    }
}
.two-col {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    /*padding: 1%;*/
    width: 48%;
}
.circle-image:before { /* ratio */
    content:"";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.circle-image img { /* position inside square */
    position: absolute;
    top: 0;
    left:0;
    height: 100% !important;
    width: auto !important;
    /* overriding max-width ruins the fit */
    object-fit: cover;
}
.circle-image {
    position: relative;
    display: inline-block;
    width: 400px;
    max-width: 40%;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    float: right;
    margin-left: 20px;
    margin-bottom: 10px;
}


@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .two-col {
        width: 100%;
        padding: 0px;
    }
    .circle-image {
        display: block;
        float: none;
        max-width: 100%;
        margin: 20px auto;
    }
    div.yellow-border {
        width: 100%;
        max-width: 100%;
        padding: 20px 10px;
        float: none;
        border-width: 2vw;
        margin: 20px 0px;
    }
}
@media only screen and (max-width: 600px){
/** content headings smaller sizes **/
    h1 {
        font-size: 2.2em;
    }
    h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.2em;
    }
}